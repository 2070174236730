<template>
  <div>
    <TermsForm
      :language="language"
      :terms-type="termsType"
      :version="version"
    ></TermsForm>
  </div>
</template>

<script>
import TermsForm from '@/components/TermsForm';

export default {
  name: 'GameTerms',
  components: { TermsForm },

  computed: {
    language() {
      return this.$route.params.language;
    },

    termsType() {
      return this.$route.params.type;
    },

    version() {
      return this.$route.params.version;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
