<template>
  <div>
    <GameTerms />
  </div>
</template>

<script>
// @ is an alias to /src
import GameTerms from '@/components/GameTerms.vue';

export default {
  name: 'GameTermsPage',
  components: {
    GameTerms,
  },
};
</script>
